<template>
    <div class="order-edit-view">
        <bxs-top-nav>
            <template #undo>
                <bxs-btn
                icon
                @click="undo">
                    <bxs-icon name="chevron-left" />
                </bxs-btn>
            </template>

            <span>{{ is_new ? 'Crea' : 'Modifica' }} commessa</span>

            <template #append>
                <bxs-menu title="Azioni commessa">
                    <bxs-list>
                        <bxs-list-item
                        prepend-icon="chevron-left"
                        @click="undo">Home</bxs-list-item>

                        <bxs-list-item
                        prepend-icon="edit"
                        @click="handleUndoConfirm('submit')">Salva ed esci</bxs-list-item>

                        <bxs-list-item
                        v-if="!is_new"
                        prepend-icon="check"
                        text-color="success"
                        @click="save('confirm')">Finalizza commessa</bxs-list-item>

                        <bxs-list-item
                        v-if="!is_new"
                        prepend-icon="file-earmark-text-fill"
                        to="/orders/new/edit">Nuova commessa</bxs-list-item>

                        <bxs-list-item
                        v-if="!is_new"
                        prepend-icon="trash"
                        @click="show_pre_trash = true">Elimina commessa</bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            stepper
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <bxs-form
                ref="form"
                @submit="save">
                    <!-- customer: [() => $refs.form.validate(['customer.business_name', 'customer.vat_code'])], -->
                    <!-- :conditions="{
                        products: [() => model.rows.length > 0],
                        delivery: [() => $refs.form.validate(['delivery.date'])],
                        payment: [() => $refs.form.validate(['payment.method'])]
                    }" -->
                    <bxs-stepper
                    ref="stepper"
                    v-model="step"
                    :items="steps"
                    @change:before="$refs.form.validate()"
                    @change:after="$refs.form.resetValidation()">
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            data
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.data>
                            <div class="mb-ui">
                                <p>Dati commessa</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.number_id"
                                    label="Numero"
                                    readonly
                                    name="number_id"
                                    required />
                                </bxs-col>

                                <!-- <bxs-col cols="6">
                                    <bxs-text-field
                                    v-model="model.code"
                                    label="Codice"
                                    name="code" />
                                </bxs-col> -->
                            </bxs-row>

                            <bxs-row>
                                <bxs-col cols="8">
                                    <bxs-date-picker
                                    v-model="model.open_date"
                                    mode="date"
                                    is24hr
                                    color="blue"
                                    is-required
                                    title-position="left"
                                    is-expanded>
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <bxs-text-field
                                            :model-value="inputValue"
                                            readonly
                                            label="Data apertura"
                                            name="open_date"
                                            required
                                            @click="togglePopover()" />
                                        </template>
                                    </bxs-date-picker>
                                </bxs-col>

                                <bxs-col cols="4">
                                    <bxs-text-field
                                    model-value="EUR"
                                    readonly
                                    label="Valuta"
                                    name="currency" />
                                </bxs-col>
                            </bxs-row>

                            <bxs-text-field
                            v-model="model.description"
                            label="Oggetto / Descrizione"
                            name="description" />
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            step customer
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.customer>
                            <div class="flex nowrap align-center mb-ui">
                                <p>Dati cliente</p>

                                <bxs-spacer></bxs-spacer>

                                <bxs-btn
                                text
                                color="info"
                                append-icon="chevron-right"
                                @click="drawer_customers.on = true">Cerca cliente</bxs-btn>
                            </div>

                            <bxs-row align="center">
                                <bxs-col cols="12">
                                    <!-- @click="hadndleFocusCustomerField" -->
                                    <bxs-text-field
                                    v-model="model.customer.business_name"
                                    label="Denominazione"
                                    name="customer.business_name"
                                    required
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.customer.vat_code"
                                    label="Partita IVA / Codice fiscale"
                                    required
                                    name="customer.vat_code"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.customer.sdi"
                                    label="Codice SDI"
                                    required
                                    name="customer.sdi"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    ref="field_customer_email"
                                    v-model="model.customer.email"
                                    label="Email"
                                    name="customer.email"
                                    required
                                    clearable
                                    :rules="['email']" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.customer.billing_address.address"
                                    label="Indirizzo (sede legale)"
                                    required
                                    name="billing_adress.address"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="8">
                                    <bxs-text-field
                                    v-model="model.customer.billing_address.city"
                                    label="Città"
                                    name="billing_adress.city" />
                                </bxs-col>

                                <bxs-col cols="4">
                                    <bxs-text-field
                                    v-model="model.customer.billing_address.zip_code"
                                    label="CAP"
                                    name="billing_adress.zip_code" />
                                </bxs-col>

                                <bxs-col cols="4">
                                    <bxs-text-field
                                    v-model="model.customer.billing_address.province"
                                    label="Provincia"
                                    name="billing_adress.province" />
                                </bxs-col>

                                <bxs-col cols="8">
                                    <bxs-text-field
                                    v-model="model.customer.billing_address.nation"
                                    label="Paese"
                                    name="billing_adress.nation" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.customer.billing_address.note"
                                    label="Note sede legale"
                                    name="billing_adress.note" />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            products
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.products>
                            <div
                            v-if="!model.rows.length"
                            class="text-center mb-12 mt-6">
                                <img
                                src="/img/grid.png"
                                class="mb-6 mx-auto"
                                style="max-width: 80px;">
                                <h6>Nessun articolo</h6>
                                <p class="text-mute">Seleziona un articolo esistente.</p>
                            </div>

                            <!--  -->
                            <div v-else>
                                <div class="flex align-center mb-ui">
                                    <p>Articoli</p>
                                    <bxs-spacer></bxs-spacer>
                                    <bxs-btn
                                    text
                                    color="info"
                                    @click="is_edit_articles = !is_edit_articles">{{ !is_edit_articles ? 'Modifica' : 'Fine' }}</bxs-btn>
                                </div>

                                <draggable
                                v-model="model.rows"
                                :disabled="!is_edit_articles || model.rows.length <= 1"
                                ghost-class="row-ghost"
                                group="products"
                                item-key="product.id"
                                :animation="500"
                                tag="ul">
                                    <template #item="{ element, index }">
                                        <li>
                                            <row-item
                                            :item="element"
                                            :draggable="model.rows.length > 1"
                                            :mode="is_edit_articles ? 'edit' : 'view'"
                                            @select="handleSelectProduct(element)"
                                            @remove="removeRow(element, index)" />

                                            <div
                                            v-if="index < model.rows.length - 1"
                                            class="bxs-divider my-ui"></div>
                                        </li>
                                    </template>
                                </draggable>

                                <!-- <ul>
                                    <li
                                    v-for="(row, i) in model.rows"
                                    :key="i">
                                        <row-item
                                        :item="row"
                                        :mode="is_edit_articles ? 'edit' : 'view'"
                                        @select="handleSelectProduct(row)"
                                        @remove="removeRow(row, i)" />

                                        <div
                                        v-if="i < model.rows.length - 1"
                                        class="bxs-divider my-ui"></div>
                                    </li>
                                </ul> -->
                            </div>

                            <div class="mt-ui">
                                <bxs-btn
                                prepend-icon="search"
                                color="info"
                                block
                                @click="drawer_products.on = true">Cerca articoli</bxs-btn>
                            </div>
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            delivery
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.delivery>
                            <div class="mb-ui">
                                <p>Metodo di spedizione</p>
                            </div>

                            <div>
                                <bxs-date-picker
                                v-model="model.delivery.date"
                                mode="date"
                                is24hr
                                color="blue"
                                title-position="left"
                                is-required
                                is-expanded>
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <bxs-text-field
                                        :model-value="inputValue"
                                        name="delivery.date"
                                        readonly
                                        label="Data consegna"
                                        @click="togglePopover()" />
                                    </template>
                                </bxs-date-picker>

                                <bxs-textarea-field
                                v-model="model.delivery.note"
                                label="Note spedizione"
                                name="payment.note" />
                            </div>
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            payment
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.payment>
                            <div class="mb-ui">
                                <p>Metodo di pagamento</p>
                            </div>

                            <div>
                                <bxs-list class="mb-ui">
                                    <bxs-list-item
                                    v-for="(pm, i) in $tfork.enums.payment_method.types"
                                    :key="i"
                                    divider
                                    @click="setPaymentMethod(pm)">
                                        <div class="flex nowrap align-center justify-between">
                                            <div class="flex-1">
                                                <p>{{ pm.text }}</p>
                                                <small class="text-mute">Sconto {{ pm.discount }}%</small>
                                            </div>

                                            <bxs-icon
                                            width="20"
                                            height="20">
                                                <path v-if="model.payment.method !== pm.value" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path v-else d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </bxs-icon>
                                        </div>
                                    </bxs-list-item>
                                </bxs-list>

                                <div class="my-4">
                                    <p class="mb-1">IVA %</p>
                                    <bxs-number-field
                                    v-model.number="model.cash.vat"
                                    name="cash.vat"
                                    :hide-actions="true"
                                    placeholder="IVA"
                                    :min="0"
                                    :max="100"
                                    suffix="%" />
                                </div>

                                <bxs-textarea-field
                                v-model="model.payment.note"
                                label="Note pagamento"
                                name="payment.note" />
                            </div>
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            recap
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.recap="{ to }">
                            <div class="mb-ui">
                                <p>Riepilogo</p>
                            </div>

                            <!-- customer -->
                            <bxs-card class="mb-6">
                                <div
                                class="flex align-center bxs-pointer"
                                @click="to('customer')">
                                    <p>Dati cliente</p>
                                    <bxs-spacer></bxs-spacer>
                                    <bxs-icon name="chevron-right"></bxs-icon>
                                </div>
                                <div class="bxs-divider my-2"></div>

                                <div class="mt-ui">
                                    <p class="mb-1 text-500">{{ model.customer.business_name }}</p>
                                    <p><small>{{ model.customer.vat_code }}</small></p>
                                    <p class="text-mute"><small>{{ model.customer.billing_address.address }}</small></p>
                                </div>
                            </bxs-card>

                            <!-- details -->
                            <bxs-card class="mb-6">
                                <div
                                class="flex align-center bxs-pointer"
                                @click="to('data')">
                                    <p>Dati commessa</p>
                                    <bxs-spacer></bxs-spacer>
                                    <bxs-icon name="chevron-right"></bxs-icon>
                                </div>
                                <div class="bxs-divider my-2"></div>

                                <div class="mt-ui">
                                    <p class="mb-1 text-500">Commessa #{{ model.number_id }}</p>
                                    <p class="text-mute"><small>Data apertura {{ $filters.toHumanDate(model.created_at) }}</small></p>
                                </div>
                            </bxs-card>

                            <!-- products -->
                            <bxs-card class="mb-6">
                                <div
                                class="flex align-center"
                                @click="to('products')">
                                    <p>Articoli</p>
                                    <bxs-spacer></bxs-spacer>
                                    <bxs-icon name="chevron-right"></bxs-icon>
                                </div>
                                <div class="bxs-divider my-2"></div>

                                <ul v-if="model.rows.length > 0">
                                    <li
                                    v-for="(row, i) in model.rows"
                                    :key="i">
                                        <row-recap :item="row" />
                                        <div class="bxs-divider my-ui"></div>
                                    </li>
                                </ul>
                            </bxs-card>

                            <!-- shipping -->
                            <bxs-card class="mb-6">
                                <div
                                class="flex align-center bxs-pointer"
                                @click="to('delivery')">
                                    <p>Dettagli spedizione</p>
                                    <bxs-spacer></bxs-spacer>
                                    <bxs-icon name="chevron-right"></bxs-icon>
                                </div>
                                <div class="bxs-divider my-2"></div>

                                <div class="flex nowrap mt-ui">
                                    <p>Data consegna <span class="ml-2 text-500">{{ $filters.toHumanDate(model.delivery.date) }}</span></p>
                                    <bxs-spacer></bxs-spacer>
                                    <p v-html="$filters.toHumanPrice(model.delivery.gross)"></p>
                                </div>
                            </bxs-card>

                            <!-- payments -->
                            <bxs-card class="mb-6">
                                <div
                                class="flex align-center bxs-pointer"
                                @click="to('payment')">
                                    <p>Dettagli pagamento</p>
                                    <bxs-spacer></bxs-spacer>
                                    <bxs-icon name="chevron-right"></bxs-icon>
                                </div>
                                <div class="bxs-divider my-2"></div>

                                <div class="flex nowrap mt-ui">
                                    <p>{{ $filters.toHumanPaymentMethod(model.payment.method) }}</p>
                                    <bxs-spacer></bxs-spacer>
                                    <p>Sconto del {{ model.payment.discount }}%</p>
                                </div>
                            </bxs-card>

                            <!-- cash -->
                            <bxs-card>
                                <div class="flex align-center mb-2">
                                    <p>Imponibile</p>
                                    <bxs-spacer></bxs-spacer>
                                    <p v-html="$filters.toHumanPrice(model.cash.total_net)"></p>
                                </div>

                                <div class="flex align-center mb-2">
                                    <p>IVA {{ model.cash.vat }}%</p>
                                    <bxs-spacer></bxs-spacer>
                                    <p v-html="$filters.toHumanPrice((model.cash.total_gross - model.delivery.gross) - model.cash.total_net)"></p>
                                </div>

                                <div class="flex align-center text-info text-500">
                                    <p>Totale</p>
                                    <bxs-spacer></bxs-spacer>
                                    <p v-html="$filters.toHumanPrice(model.cash.total_gross)"></p>
                                </div>
                            </bxs-card>
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            bottom navs
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #actions="{ is_last_step, is_first_step, prev, next, step }">
                            <bxs-bottom-nav
                            v-if="!is_last_step"
                            bottom="60">
                                <bxs-layout class="background">
                                    <bxs-btn
                                    v-if="step.value === 'customer'"
                                    block
                                    :disabled="!model.customer.business_name"
                                    dark
                                    :color="!model.customer.ref ? 'success' : 'info'"
                                    @click="saveCustomer">{{ !model.customer.ref ? 'Salva in anagrafica' : 'Aggiorna anagrafica' }}</bxs-btn>

                                    <bxs-list>
                                        <bxs-divider></bxs-divider>
                                        <bxs-list-item
                                        append-icon="edit"
                                        @click="menu_agent.on = true">
                                            <small class="text-mute">Descrizione / Oggetto commessa</small>
                                            <br>
                                            <small>{{ model.agent.description || '--' }}</small>
                                        </bxs-list-item>
                                        <bxs-divider></bxs-divider>
                                    </bxs-list>
                                </bxs-layout>
                            </bxs-bottom-nav>

                            <!-- step control -->
                            <bxs-bottom-nav v-if="!is_last_step">
                                <bxs-toolbar color="background">
                                    <bxs-btn
                                    :disabled="is_first_step"
                                    icon
                                    @click="prev">
                                        <bxs-icon name="chevron-left"></bxs-icon>
                                    </bxs-btn>

                                    <bxs-spacer></bxs-spacer>

                                    <span class="text-info text-600" v-html="$filters.toHumanPrice(model.cash.total_gross)"></span>

                                    <bxs-spacer></bxs-spacer>

                                    <bxs-btn
                                    icon
                                    @click="next">
                                        <bxs-icon name="chevron-right"></bxs-icon>
                                    </bxs-btn>
                                </bxs-toolbar>
                            </bxs-bottom-nav>

                            <!-- recap  -->
                            <bxs-bottom-nav v-else>
                                <bxs-toolbar color="background">
                                    <bxs-btn
                                    block
                                    dark
                                    color="info"
                                    class="mr-1"
                                    @click="save('save')">Salva</bxs-btn>

                                    <bxs-btn
                                    block
                                    dark
                                    color="success"
                                    class="ml-2"
                                    @click="save('confirm')">Finalizza commessa</bxs-btn>
                                </bxs-toolbar>
                            </bxs-bottom-nav>
                        </template>
                    </bxs-stepper>
                </bxs-form>
            </bxs-layout>
        </section>
        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            end stepper
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            trash
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-menu
        v-model="show_pre_trash"
        closable
        solo>
            <template #header>
                <h6>Elimina la commessa</h6>
                <p class="text-mute">Vuoi eliminare questa commessa?</p>
            </template>

            <template #actions>
                <div class="flex pa-layout">
                    <bxs-btn
                    block
                    color="error"
                    class="mr-2"
                    @click="trash">Elimina</bxs-btn>

                    <bxs-btn
                    block
                    color="info"
                    class="ml-2"
                    @click="show_pre_trash = false">Annulla</bxs-btn>
                </div>
            </template>
        </bxs-menu>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            undo
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-menu
        v-model="show_pre_undo"
        closable
        solo>
            <template #header>
                <h6>Esci dalla {{ is_new ? 'creazione' : 'modifica' }}</h6>
                <p class="text-mute">Cosa vuoi fare con la commesa su cui stai lavorando?</p>
            </template>

            <template #actions>
                <div class="flex pa-layout">
                    <bxs-btn
                    block
                    color="info"
                    class="mr-2"
                    @click="handleUndoConfirm('submit')">{{ is_new ? 'Salva come bozza' : 'Salva' }}</bxs-btn>

                    <bxs-btn
                    block
                    color="error"
                    class="ml-2"
                    @click="handleUndoConfirm('undo')">Esci</bxs-btn>
                </div>
            </template>
        </bxs-menu>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            desc temp
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-modal
        v-model="menu_agent.on"
        hide-close
        persistent>
            <template #header>
                <bxs-title>Descrizione / Oggetto commessa</bxs-title>
                <small class="text-mute">Inserisci il nome del cliente, del negozio o una piccola descrizione e prosegui con la commessa.</small>
            </template>

            <div class="pa-ui">
                <bxs-text-field
                v-model="model.agent.description"
                solo
                name="agent.description"
                placeholder="Nome del cliente, del negozio, ..." />
            </div>

            <template #actions>
                <div class="flex nowrap align-center">
                    <bxs-btn
                    block
                    :disabled="!model.agent.description"
                    color="secondary"
                    tile
                    between
                    append-icon="chevron-right"
                    @click="menu_agent.on = false">Continua</bxs-btn>

                    <bxs-btn
                    block
                    color="error"
                    tile
                    between
                    @click="handleUndoConfirm('undo')">Esci</bxs-btn>
                </div>
            </template>
        </bxs-modal>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            exists order by customer
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-menu
        v-model="menu_exists_orders.on"
        solo>
            <template #header>
                <h6>{{ menu_exists_orders.docs.length }} bozze con lo stesso cliente</h6>
                <p>Prosegui o seleziona una commessa esistente</p>
            </template>

            <bxs-list>
                <bxs-list-item
                v-for="order in menu_exists_orders.docs"
                :key="order.id"
                spacer
                append-icon="chevron-right"
                :href="`/orders/${order.id}/edit`">
                    <p>{{ order.customer.business_name }}</p>
                    <p><small>Ultimo salvataggio il {{ $filters.toHumanDate(order.updated_at) }}</small></p>
                    <p><small>{{ !order.rows.length ? 'Nessun articolo' : order.rows.length + ' articoli' }}</small></p>
                </bxs-list-item>
            </bxs-list>

            <template #actions>
                <bxs-list-item
                append-icon="close"
                spacer
                @click="menu_exists_orders.on = false">Chiudi e prosegui</bxs-list-item>
            </template>
        </bxs-menu>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            drawer select customer
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <search-customers-drawer
        v-model:on="drawer_customers.on"
        @select="handleSelectCustomer"
        @add:business_name="handleCustomerAddBusinessName" />

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            drawer select product
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <search-products-drawer
        v-model:on="drawer_products.on"
        @select="handleSelectProduct">
            <template #item.append="{ item }">
                <small
                v-if="products_configurables_in_ids.includes(item.id)"
                class="ml-ui text-uppercase text-success">In commessa</small>
            </template>
        </search-products-drawer>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            drawer product row
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <row-edit-drawer
        v-model:on="drawer_row.on"
        v-model:quantities="drawer_row.quantities"
        :product="drawer_row.product"
        :loading="drawer_row.loading"
        @change="handleChangeProductEdit">
            <template #actions>
                <div class="bxs-divider"></div>

                <div class="pa-layout">
                    <bxs-btn
                    color="success"
                    :disabled="drawer_row.loading"
                    block
                    dark
                    @click="addRows">
                        <span>Salva</span>
                    </bxs-btn>
                </div>
            </template>
        </row-edit-drawer>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import SearchCustomersDrawer from '@/components/project/SearchCustomersDrawer.vue'
import SearchProductsDrawer from '@/components/project/SearchProductsDrawer.vue'
import OrderEditRowEditDrawer from '@/components/project/OrderEditRowEditDrawer.vue'
import OrderEditRow from '@/components/project/OrderEditRow.vue'
import OrderEditRecapRow from '@/components/project/OrderEditRecapRow.vue'

import {
    assignIn,
    // sortBy,
    isEqual
} from 'lodash'
import dotObj from 'dot-object'

dotObj.keepArray = true

export default {
    name: 'order_edit',
    components: {
        draggable,
        'search-customers-drawer': SearchCustomersDrawer,
        'search-products-drawer': SearchProductsDrawer,
        'row-edit-drawer': OrderEditRowEditDrawer,
        'row-item': OrderEditRow,
        'row-recap': OrderEditRecapRow
    },
    data () {
        return {
            preloaded: false,
            step: 0,
            steps: [
                { text: 'Articoli', value: 'products' },
                { text: 'Pagamento', value: 'payment' },
                { text: 'Spedizione', value: 'delivery' },
                { text: 'Cliente', value: 'customer' },
                { text: 'Dati commessa', value: 'data' },
                { text: 'Riepilogo', value: 'recap' }
            ],
            loading: false,
            show_pre_undo: false,
            show_pre_trash: false,
            menu_exists_orders: {
                on: false,
                docs: []
            },
            is_save_real_time_data: false,
            is_edit_articles: false,
            //
            menu_agent: {
                on: false
            },
            drawer_customers: {
                on: false
            },
            drawer_products: {
                on: false
            },
            drawer_row: {
                on: null,
                loading: false,
                product: null,
                quantities: {}
            },
            //
            model: {
                description: null,
                short_description: null,
                open_date: null,
                customer: {
                    ref: null,
                    email: null,
                    business_name: null,
                    vat_code: null,
                    sdi: null,
                    billing_address: {
                        address: null,
                        zip_code: null,
                        city: null,
                        province: null,
                        nation: null,
                        note: null
                    }
                },
                rows: [],
                delivery: {
                    method: null,
                    note: null,
                    date: null,
                    discount: 0,
                    gross: 0
                },
                payment: {
                    method: null,
                    note: null,
                    discount: 0,
                    gross: 0
                },
                cash: {
                    total_net: 0,
                    total_vat: 0,
                    total_gross: 0,
                    vat: 22
                },
                agent: {
                    ref: null,
                    name: null,
                    email: null,
                    description: null
                }
            },
            old_model: {},
            tmp_id: null
        }
    },
    computed: {
        is_new () {
            return this.$route.params.id === 'new'
        },
        is_modified () {
            return !isEqual(this.model, this.old_model)
        },
        ID () {
           return this.is_new ? null : this.$route.params.id
        },
        products_configurables_in_ids () {
            return this.model.rows.map(i => i.configurable_id)
        }
    },
    //
    created () {
        this.start()
    },
    beforeMount() {
        console.log('> Order edit beforeMount()')

        // window.addEventListener('beforeunload', this.preventNav)

        // this.$emitter.$once('hook:beforeUnmount', () => {
        //     window.removeEventListener('beforeunload', this.preventNav)
        // })
    },
    //
    watch: {
        step () {
            this.is_edit_articles = false

            if (this.is_save_real_time_data) {
                this.save('save')
            }
        },
        'drawer_products.on' () {
            this.is_edit_articles = false
        },
        model: {
            deep: true,
            handler () {
                console.log('> Order edit > watch model')
                this.calc()
            }
        }
    },
    methods: {
        // ------------------------------------------------------------------------------------------------------------------------------------
        // controls
        // ------------------------------------------------------------------------------------------------------------------------------------
        start () {
            console.log('> Order edit start()', this.is_new, this.ID)

            if (this.is_new) {
                this.menu_agent.on = true

                this.model.code = Date.now()
                this.model.open_date = new Date()
                this.old_model = JSON.parse(JSON.stringify(this.model))

                this.model.agent.ref = this.$tfork.auth.me.id
                this.model.agent.email = this.$tfork.auth.me.email
            } else {
                this.$tfork.orders.doc(this.ID).then((doc) => {
                    assignIn(this.model, doc)
                    this.old_model = JSON.parse(JSON.stringify(this.model))
                    this.preloaded = true
                })
            }
        },
        stop () {
            console.log('> Order edit stop()')

            // this.$emitter.$off('hook:beforeUnmount')

            this.loading = false
            this.show_pre_undo = false
            this.menu_exists_orders.on = false
            this.is_save_real_time_data = false
            this.is_edit_articles = false
            this.drawer_customers.on = false
            this.drawer_products.on = false

            this.menu_agent.on = false
            this.drawer_customers.on = false
            this.drawer_products.on = false
            this.drawer_row = {
                on: false,
                loading: false,
                product: null,
                quantities: {}
            }

            this.model = {
                description: null,
                short_description: null,
                open_date: null,
                customer: {
                    ref: null,
                    email: null,
                    business_name: null,
                    vat_code: null,
                    sdi: null,
                    billing_address: {
                        address: null,
                        zip_code: null,
                        city: null,
                        province: null,
                        nation: null,
                        note: null
                    }
                },
                rows: [],
                delivery: {
                    method: null,
                    note: null,
                    date: null,
                    discount: 0,
                    gross: 0
                },
                payment: {
                    method: null,
                    note: null,
                    discount: 0,
                    gross: 0
                },
                cash: {
                    total_net: 0,
                    total_vat: 0,
                    total_gross: 0,
                    vat: 22
                },
                agent: {
                    ref: null,
                    name: null,
                    email: null,
                    description: null
                }
            }

            this.calc()
        },
        preventNav (evt) {
            console.log('> Order edit > preventNav()', evt)
            this.show_pre_undo = true
            evt.preventDefault()
            evt.returnValue = ''
        },
        undo () {
            this.show_pre_undo = true
        },
        async handleUndoConfirm (fn) {
            if (fn === 'submit') await this.save('save')
            this.$router.go(-1)
        },
        // ------------------------------------------------------------------------------------------------------------------------------------
        // handlers
        // ------------------------------------------------------------------------------------------------------------------------------------
        handleSelectCustomer (val) {
            console.log('> Order edit > handleSelectCustomer()', val)

            this.model.customer = {
                ref: val.id,
                email: val.email,
                business_name: val.business_name,
                vat_code: val.vat_code,
                sdi: val.sdi,
                note: val.note,
                billing_address: {
                    address: val.billing_address.address,
                    zip_code: val.billing_address.zip_code,
                    city: val.billing_address.city,
                    province: val.billing_address.province,
                    nation: val.billing_address.nation,
                    note: val.billing_address.note
                }
            }

            this.drawer_customers.on = false
        },
        handleCustomerAddBusinessName (val) {
            console.log('> Order edit > handleCustomerAddBusinessName()', val)

            this.model.customer = {
                ref: null,
                email: null,
                business_name: val,
                vat_code: null,
                sdi: null,
                note: null,
                billing_address: {
                    address: null,
                    zip_code: null,
                    city: null,
                    province: null,
                    nation: null,
                    note: null
                }
            }

            this.drawer_customers.on = false
        },
        hadndleFocusCustomerField () {
            console.log('> Order edit > hadndleFocusCustomerField()')

            if (!this.model.customer.business_name) {
                this.drawer_customers.on = true
            }
        },
        async handleSelectProduct (item) {
            if (!item || this.is_edit_articles) return

            const is_edit = 'product' in item
            console.log('> Order edit > selectProduct()', item, is_edit)

            this.drawer_row.loading = true
            this.drawer_row.on = true
            const product = await this.$tfork.products.doc(item.configurable_id || item.id, {
                populate: 'imgs variant_products'
            })

            if (product.variant_products.length > 1) {
                product.variant_products = product.variant_products.sort((a, b) => {
                    return a.size.it.localeCompare(b.size.it, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                })

                product.variant_products.forEach((item) => {
                    const row = this.model.rows.find(i => i.product.id === item.id)
                    this.drawer_row.quantities[item.id] = row ? row.quantity : null
                })
            }

            this.drawer_row.product = product
            this.drawer_row.loading = false

            return product
        },
        handleChangeProductEdit () {
            console.log('> Order edit > handleChangeProductEdit()')
            // this.calcRow()
        },
        // ------------------------------------------------------------------------------------------------------------------------------------
        // rows
        // ------------------------------------------------------------------------------------------------------------------------------------
        setPaymentMethod (pm) {
            this.model.payment.method = pm.value
            this.model.payment.discount = pm.discount
        },
        addRows () {
            // item = JSON.parse(JSON.stringify(item))
            // exit = exit || !!is_in

            console.log('> Order edit > addRow()', this.drawer_row)
            let has_editing = false

            for (const prod_var_id in this.drawer_row.quantities) {
                const prod_var = this.drawer_row.product.variant_products.find(i => i.id === prod_var_id)

                if (prod_var) {
                    const row = this.model.rows.find(i => i.product.id === prod_var.id)
                    const quantity = this.drawer_row.quantities[prod_var.id]

                    if (quantity) {
                        has_editing = true

                        if (!row) {
                            this.model.rows.push({
                                id: prod_var.id,
                                sku: this.drawer_row.product.sku,
                                configurable_id: this.drawer_row.product.id,
                                product: {
                                    id: prod_var.id,
                                    name: this.drawer_row.product.name.it,
                                    sku: prod_var.sku,
                                    size: prod_var.size.it,
                                    img: this.drawer_row.product.imgs.length > 0 ? this.drawer_row.product.imgs[0] : null
                                },
                                quantity,
                                price_net: prod_var.prices.net,
                                price_net_revenue: prod_var.prices.net * quantity
                            })
                        } else {
                            console.log('row', row)

                            row.quantity = quantity
                            row.price_net = prod_var.prices.net
                            row.price_net_revenue = prod_var.prices.net * row.quantity
                        }
                    }
                }
            }

            if (has_editing) {
                this.$toast.success('Articoli salvati')
            }
        },
        removeRow (item, i) {
            console.log('> Order edit > removeRow()', i)

            this.model.rows.splice(i, 1)

            setTimeout(() => {
                this.is_edit_articles = this.model.rows.length > 0
            }, 250)
        },
        // ------------------------------------------------------------------------------------------------------------------------------------
        // calcs
        // ------------------------------------------------------------------------------------------------------------------------------------
        calc () {
            if (!this.model.rows.length) return

            console.log('> Order edit > calc()')

            let total_net = 0
            // eslint-disable-next-line
            let total_gross = 0
            let shipping_price = 0
            const vat = this.model.cash.vat

            // 1 calc => tot imponibile [v]
            // 2 calc => tot_imponibile - sconto da metodo di pagamento []
            // 3 calc => totale imponibile + iva 22% []
            // 4 calc => totale lordo + shipping []

            // 1
            this.model.rows.forEach((item) => {
                total_net += item.price_net_revenue
            })

            if (this.model.payment.discount > 0) {
                total_net -= total_net * (this.model.payment.discount / 100)
            }

            // 3
            total_gross = total_net + (total_net * (vat / 100))

            // 4
            const shipping_additional = this.$tfork.enums.shipping_method.additional_price
            if (total_gross < shipping_additional.threshold) {
                shipping_price = shipping_additional.value
            }
            total_gross += shipping_price

            this.model.delivery.gross = shipping_price
            this.model.cash.total_net = total_net
            this.model.cash.total_gross = total_gross
            this.model.cash.total_vat = total_gross / (vat / 100)
        },
        // ------------------------------------------------------------------------------------------------------------------------------------
        // actions
        // ------------------------------------------------------------------------------------------------------------------------------------
        async saveCustomer () {
            console.log('> Order edit > saveCustomer()')

            this.$store.commit('set_is_loading', true)

            try {
                const id = await this.$tfork.customers.save(this.model.customer.ref, {
                    ...this.model.customer,
                    agent: this.$tfork.auth.me.id
                })

                this.model.customer.ref = id

                this.$toast.success('Cliente salvato con successo')
            } catch (err) {
                // console.log('saveCustomer() error', err)

                if (err.fields && Object.keys(err.fields).length > 0) {
                    let v = ''
                    for (const k in err.fields) v += err.fields[k]
                    throw new Error(v)
                }

                throw new Error(err.message || err.code || err)
            } finally {
                this.$store.commit('set_is_loading', false)
            }
        },
        async trash () {
            console.log('> Order edit > trash()')

            this.$store.commit('set_is_loading', true)
            this.loading = true

            try {
                await this.$tfork.orders.remove(this.ID)
                this.$router.replace('/')
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        },
        async save (action) {
            console.log('> Order edit > save()', action, this.is_new)

            this.$store.commit('set_is_loading', true)
            this.loading = true

            try {
                // controls
                // customer[ref, vat_code, sdi, email] [v]
                // metodo di pagamento [v]
                if (action === 'confirm') {
                    if (!this.model.payment.method) {
                        this.$refs.stepper.to('payment')
                        throw new Error('Attenzione, metodo di pagamento richiesto')
                    }

                    if (!this.model.customer.ref && !this.model.customer.vat_code && !this.model.customer.sdi && !this.model.customer.email) {
                        this.$refs.stepper.to('customer')
                        throw new Error('Attenzione, campi cliente richiesti')
                    }

                    const check_email = this.$tfork.enums.fields.rules.email(this.model.customer.email)

                    if (typeof check_email === 'string') {
                        this.$refs.stepper.to('customer')
                        throw new Error('Attenzione, email cliente non valida')
                    }
                }

                const body = dotObj.dot(this.model)

                const _id = await this.$tfork.orders[action](this.ID, body)

                if (action === 'confirm') {
                    const doc = await this.$tfork.orders.doc(_id)

                    this.$tfork.sendMail({
                        from: 'noreply@tfork.it',
                        to: process.env.NODE_ENV === 'development' ? 'simon3fusco@gmail.com' : this.model.customer.email,
                        subject: `TforK | Riepilogo commessa #${doc.number_id}`,
                        template: 'order.customer_confirm',
                        data: {
                            url: `${process.env.NODE_ENV === 'development' ? 'http://localhost:8082' : 'https://agenti.tfork.it'}/o/${doc.id}/c/${doc.customer_authcode}`
                        }
                    })

                    this.$router.replace('/orders/' + _id)
                } else {
                    console.log(this.$route.params)

                    if (this.is_new) {
                        this.$router.push('/orders/' + _id + '/edit')
                    }

                    this.$toast.success('Commessa salvata')
                }
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    },
    //
    beforeUnmount () {
        console.log('beforeUnmount')
        this.stop()
    }
    // beforeRouteLeave (to, from, next) {
    //     const answer = window.confirm('Vuoi davvero uscire? Attenzione ai cambiamenti non salvati!')
    //     this.show_pre_undo = true

    //     if (answer) next()
    //     else next(false)
    // }
}
</script>