<template>
    <div>
        <bxs-navbar v-if="is_preloaded && show_top_nav" />

        <!-- view -->
        <main
        v-if="is_preloaded"
        class="app__main">
            <router-view
            ref="view"
            v-slot="{ Component }">
                <!-- name="page"
                mode="out-in"
                appear -->
                <transition
                @enter="enter"
                @leave="leave"
                mode="out-in"
                appear>
                    <component
                    v-if="is_preloaded"
                    ref="page"
                    :key="$route.name"
                    :is="Component" />
                </transition>
            </router-view>
        </main>

        <bxs-bottom-nav v-if="is_preloaded && !!$tfork.auth.me && show_bottom_nav">
            <bxs-toolbar>
                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="house"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                to="/orders">
                    <bxs-icon name="search"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-menu min-height="35vh">
                    <template #activator="{ on }">
                        <bxs-btn
                        v-on="on"
                        fab
                        color="tertiary"
                        large>
                            <bxs-icon name="plus-lg"></bxs-icon>
                        </bxs-btn>
                    </template>

                    <bxs-list>
                        <bxs-list-item
                        prepend-icon="file-earmark-text-fill"
                        to="/orders/new/edit">Nuova commessa</bxs-list-item>
                    </bxs-list>
                </bxs-menu>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                to="/me">
                    <bxs-icon name="person"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                disabled
                to="/me/settings">
                    <bxs-icon name="settings"></bxs-icon>
                </bxs-btn>
            </bxs-toolbar>
        </bxs-bottom-nav>

        <transition name="fade" mode="out-in">
            <bxs-loader
            v-if="is_loading"
            logo
            fullscreen></bxs-loader>
        </transition>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils'

import Navbar from '@/components/layout/Navbar.vue'

export default {
    name: 'App',
    components: {
        'bxs-navbar': Navbar
    },
    computed: {
        ...mapState({
            access: state => state.access,
            is_loading: state => state.is_loading,
            is_preloaded: state => state.is_preloaded
        }),
        show_top_nav () {
            const v = ['order_edit', 'order_customer_view']
            return !this.$store.state.is_mobile && !v.includes(this.$route.name)
        },
        show_bottom_nav () {
            const v = ['order', 'order_edit', 'order_customer_view']
            return !v.includes(this.$route.name)
        }
    },
    mounted () {
        this.play()
    },
    methods: {
        async play () {
            console.log(this.$tfork)

            this.resize()
            window.addEventListener('resize', this.resize)
            this.$store.commit('set_is_loading', true)

            if (this.$route.name !== 'ui_components') {
                await this.$tfork.auth.getAccess()

                if (this.$tfork.auth.me) {
                    await this.$tfork.getEnums()
                    await this.$tfork.me.get()
                    await this.$router.push(sessionStorage.getItem('tfk_last_page') || '/')
                }
            }

            this.$tfork.on('access-token-expired', (evt, val) => {
                // alert('Access expired!')
                // this.$router.go()
            })

            this.$store.commit('set_is_preloaded', true)
            this.$store.commit('set_is_loading', false)
        },
        resize () {
            fixVh()
            this.$store.commit('resize')
        },
        enter (el, next) {
            gsap.fromTo(el, {
                x: 40,
                autoAlpha: 0
            }, {
                x: 0,
                autoAlpha: 1,
                duration: 0.5,
                ease: 'expo.out',
                clearProps: 'all',
                onComplete: next
            })
        },
        leave (el, next) {
            gsap.fromTo(el, {
                x: 0,
                autoAlpha: 1
            }, {
                x: 40,
                autoAlpha: 0,
                duration: 0.4,
                ease: 'expo.in',
                onComplete: next
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>